import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { injectIntl } from 'gatsby-plugin-react-intl';

import AppIcons from '@components/AppIcons';
import HeroIllustration from '@components/HeroIllustration';
import Sections from '@components/Sections';

import Seo from '@core/Seo';
import RichText from '@core/RichText';

import mq, { mqonly } from '@mq';

const HomePage = ({
  location,
  data: {
    contentfulHomepage: { title, subtitle, description, callToAction, sections },
  },
}) => (
  <>
    <Seo
      pageTitle={title}
      pageDescription="Our physical space and digital platform help people connect across their neighbourhood, providing everything they need to live their best work, leisure, and social lives."
    />
    <main>
      <section>
        <h1>{title}</h1>
        <Subtitle text={subtitle} />
        <IllustrationContainer>
          <HeroIllustration />
        </IllustrationContainer>
        <Description text={description} />
        <CallToAction>
          <CallToActionText text={callToAction} />
          <AppIcons context="homepage" />
        </CallToAction>
      </section>
      <Sections sections={sections} locationState={location.state} />
    </main>
  </>
);

const Subtitle = styled(RichText)`
  grid-column: 1 / -1;
  text-align: center;
  margin-bottom: var(--space-s);
  font: var(--font-subtitle);

  ${mq.medium} {
    grid-column: 2 / -2;
    margin-bottom: var(--space-l);
  }

  ${mq.large} {
    grid-column: 3 / -3;
  }

  ${mq.huge} {
    grid-column: 6 / -6;
  }
`;

const IllustrationContainer = styled.div`
  grid-column: 1 / -1;
  margin-bottom: calc(var(--space-m) - var(--space-xxs));

  ${mq.huge} {
    grid-column: 5 / -5;
  }
`;

const Description = styled(RichText)`
  grid-column: 1 / -1;
  text-align: center;
  margin-bottom: var(--space-m);

  ${mq.small} {
    margin-bottom: var(--space-s);
  }

  ${mq.medium} {
    grid-column: 2 / -2;
  }

  ${mq.large} {
    grid-column: 3 / -3;
  }

  ${mq.huge} {
    grid-column: 6 / -6;
  }
`;

const CallToAction = styled.div`
  text-align: center;
  grid-column: 1 / -1;
  display: grid;
  row-gap: var(--space-xs);
  column-gap: var(--space-s);
  align-items: center;

  ${mq.small} {
    font: var(--font-subtitle);
    row-gap: var(--space-xxs);
  }

  ${mqonly.medium} {
    grid-column: 2 / -2;
  }

  ${mq.medium} {
    grid-column: 3 / -3;
    grid-auto-flow: column;
  }

  ${mq.large} {
    grid-column: 4 / -4;
  }

  ${mq.huge} {
    grid-column: 7 / -7;
  }
`;

const CallToActionText = styled(RichText)`
  ${mq.small} {
    p {
      white-space: initial;
    }
  }
`;

export const homepageQuery = graphql`
  query HomepageQuery($locale: String) {
    contentfulHomepage(node_locale: { eq: $locale }) {
      node_locale
      title
      subtitle {
        raw
      }
      description {
        raw
      }
      callToAction {
        raw
      }
      sections {
        ... on ContentfulEntry {
          ...sections
        }
      }
    }
  }
`;

export default injectIntl(HomePage);
