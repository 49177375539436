import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import HeroSvgComponent from '@illustration-components/hero.svg';
import HeroSvgFile from '@illustration-files/hero.svg';
import mq, { mqdown } from '@mq';

const HeroIllustration = () => {
  const ref = useRef(null);

  useEffect(() => {
    const circleTimelines = [];

    ScrollTrigger.matchMedia({
      '(max-width: 768px)': () => {
        gsap.set(ref.current, {
          '--progress': -1,
        });

        gsap.to(ref.current, {
          scrollTrigger: {
            trigger: ref.current,
            start: 'top',
            end: 'bottom bottom',
            markers: false,
            scrub: 0.05,
          },
          '--progress': 0,
        });
      },
    });

    const handAnimation = gsap.fromTo(
      '.hero-hand',
      5,
      { rotation: 0, transformOrigin: '19% 100%' },
      { rotation: 3.25, repeat: -1, transformOrigin: '19% 100%', yoyo: true }
    );

    [...document.querySelectorAll('.hero-circles')].forEach((circle, index) => {
      const timeline = gsap.timeline({
        repeat: -1,
      });

      timeline
        .fromTo(
          circle,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.1,
            ease: 'steps(1)',
            delay: index * 1.5,
          }
        )
        .to(
          circle,
          {
            x: 600,
            y: 'random(0,15)',
            duration: 65,
            ease: 'linear',
          },
          '>-0.1'
        )
        .to(
          circle,
          {
            opacity: 0,
            duration: 0.1,
            ease: 'steps(1)',
          },
          '>-0.1'
        );

      timeline.seek(245);
      circleTimelines.push(timeline);
    });

    const sunTimeline = gsap.timeline({
      repeat: -1,
      repeatDelay: 1,
    });

    sunTimeline
      .fromTo(
        '.hero-sun',
        0.1,
        { opacity: 0 },
        {
          opacity: 1,
          stagger: {
            each: 0.15,
            from: 'top right',
          },
        }
      )
      .to('.hero-sun', 0.1, {
        opacity: 0,
        delay: 3,
        stagger: {
          each: 0.15,
          from: 'top right',
        },
      });

    // eslint-disable-next-line consistent-return
    return () => {
      ScrollTrigger.getAll().forEach(trigger => {
        trigger.kill();
      });

      if (handAnimation) {
        handAnimation.kill();
      }

      if (sunTimeline) {
        sunTimeline.kill();
      }

      if (circleTimelines) {
        circleTimelines.forEach(timeline => timeline.kill());
      }
    };
  }, []);

  return (
    <Container ref={ref}>
      <StickyContainer>
        <ScrollContainer>
          <ImageContainer>
            <HeroAnimation />
            <HeroImage src={HeroSvgFile} width={1832} height={919} alt="denizen city" />
          </ImageContainer>
        </ScrollContainer>
      </StickyContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  ${mqdown.small} {
    position: relative;
    height: 400vh;
  }
`;

const StickyContainer = styled.div`
  ${mqdown.small} {
    position: sticky;
    top: 10vh;
    height: 80vh;
    max-height: 80vh;
    overflow: hidden;
    margin-left: calc(-1.5 * var(--gutter));
    margin-right: calc(-1 * var(--gutter));
    padding-right: var(--gutter);
    display: flex;
    flex-direction: column;
  }
`;

const ScrollContainer = styled.div`
  flex: 1;
`;

const ImageContainer = styled.div`
  height: 100%;
  will-change: transform;

  ${mqdown.small} {
    transform: translate3d(calc((100% - 100vw) * var(--progress)), 0, 0);
    padding-left: var(--space-xs);
    /* display: inline-block; */
    aspect-ratio: 2 / 1;
  }
`;

const HeroImage = styled.img`
  max-width: initial;
  position: relative;
  z-index: 1;

  ${mqdown.small} {
    height: 100%;
    width: auto;
  }

  ${mq.small} {
    height: auto;
    width: 100%;
  }

  .hero-circles {
    opacity: 0;
  }
`;

const HeroAnimation = styled(HeroSvgComponent)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate3d(0, 0, 0);

  ${mqdown.small} {
    left: var(--space-xs);
  }
`;

export default HeroIllustration;
